var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('BaseSteps',{staticClass:"is-vertical"},[_vm._l((_vm.events),function(event){return _c('li',{key:event.id,staticClass:"steps-segment"},[_c('p',{staticClass:"steps-marker",class:{
          'is-danger has-text-danger': [
            'commission.file.deleted',
            'commission.item.refunded',
            'commission.request.rejected',
            'commission.status.disapproved_completion',
            'commission.status.pending_customer_action' ].includes(event.type),
        }},[_c('span',{staticClass:"icon"},[_c(_vm.getSvgComponentNameForEventType(event.type),{tag:"component",attrs:{"size":"20","stroke-width":"2.5"}})],1)]),_c('div',{staticClass:"steps-content"},[_c('MediaComponent',{staticClass:"my-1"},[_c('i18n',{attrs:{"path":event.type},scopedSlots:_vm._u([{key:"customer",fn:function(){return [_c('strong',{domProps:{"textContent":_vm._s(_vm.account.name)}})]},proxy:true},{key:"profile",fn:function(){return [(_vm.profile)?_c('strong',{domProps:{"textContent":_vm._s(_vm.profile.name)}}):_vm._e()]},proxy:true}],null,true)}),_c('p',{staticClass:"ucfirst text-xs font-light",domProps:{"textContent":_vm._s(_vm.$dd(new Date(event.created_at * 1000), new Date(), true) + '.')}}),(_vm.isEventAutomatic(event))?_c('p',{staticClass:"text-xs font-light italic",domProps:{"textContent":_vm._s(' '+_vm.$t('automatic'))}}):_vm._e(),(event.resource_type === 'commission_file'
              && event.type !== 'commission.file.deleted'
              && _vm.findFile(event.resource_id))?_c('MediaCommissionFile',{staticClass:"my-2",attrs:{"file":_vm.findFile(event.resource_id),"is-owner":_vm.findFile(event.resource_id).user_id === _vm.currentUserId},scopedSlots:_vm._u([{key:"file-name",fn:function(){return [_c('RouterLink',{staticClass:"font-bold",attrs:{"to":{
                  name: 'commission.file',
                  params: {
                    COMMISSION_ID: _vm.COMMISSION_ID,
                    FILE_ID: _vm.findFile(event.resource_id).id,
                  }
                }},domProps:{"textContent":_vm._s(_vm.findFile(event.resource_id).name)}})]},proxy:true},{key:"delete-button",fn:function(){return [_c('FileDeleteButton',{attrs:{"commission-id":_vm.COMMISSION_ID,"file-id":_vm.findFile(event.resource_id).id},on:{"file-delete-error":_vm.handleError}})]},proxy:true},{key:"required-item",fn:function(){return [_c('RouterLink',{staticClass:"button has-text-link is-medium",attrs:{"to":{
                  name: 'commission.item',
                  params: {
                    COMMISSION_ID: _vm.COMMISSION_ID,
                    ITEM_ID: _vm.findFile(event.resource_id).item_id,
                  }
                }},domProps:{"textContent":_vm._s(_vm.$t('view-item'))}})]},proxy:true},{key:"download-button",fn:function(){return [_c('FileDownloadButton',{staticClass:"is-medium is-link",attrs:{"file-data":_vm.findFile(event.resource_id),"commission-id":_vm.COMMISSION_ID,"on-success-open":""},on:{"file-download-error":_vm.handleError}})]},proxy:true}],null,true)}):(event.resource_type === 'commission_item'
              && event.type !== 'commission.item.deleted'
              && _vm.findItem(event.resource_id))?_c('MediaCommissionItem',{staticClass:"my-2",attrs:{"item":_vm.findItem(event.resource_id),"is-customer":""},scopedSlots:_vm._u([{key:"purpose",fn:function(){return [_c('RouterLink',{staticClass:"mr-3 font-bold",attrs:{"to":{
                  name: 'commission.item',
                  params: {
                    COMMISSION_ID: _vm.COMMISSION_ID,
                    ITEM_ID: _vm.findItem(event.resource_id).id,
                  }
                }},domProps:{"textContent":_vm._s(_vm.findItem(event.resource_id).purpose)}})]},proxy:true},{key:"action-button",fn:function(){return [(!_vm.findItem(event.resource_id).is_paid)?_c('ItemPayButton',{attrs:{"item":_vm.findItem(event.resource_id)},on:{"item-pay-error":_vm.handleError}}):_vm._e()]},proxy:true}],null,true)}):(event.resource_type === 'commission_message'
              && _vm.findMessage(event.resource_id))?_c('MediaComponent',{staticClass:"my-2",scopedSlots:_vm._u([{key:"left",fn:function(){return [(_vm.findMessage(event.resource_id).user_id !== _vm.account.id)?_c('BaseImage',{staticClass:"is-64x64",attrs:{"src":_vm.profile ? _vm.profile.icon_url : null,"is-rounded":""}},[_c('img',{attrs:{"src":require("@/assets/img/undraw/profile_pic.svg")}})]):_vm._e()]},proxy:true},{key:"right",fn:function(){return [(_vm.findMessage(event.resource_id).user_id === _vm.account.id)?_c('BaseImage',{staticClass:"is-64x64",attrs:{"src":_vm.account.icon_url,"is-rounded":""}},[_c('img',{attrs:{"src":require("@/assets/img/undraw/profile_pic.svg")}})]):_vm._e()]},proxy:true}],null,true)},[_c('BaseNotification',[_c('BaseTextPlain',{attrs:{"text":_vm.findMessage(event.resource_id).message}})],1)],1):(event.type === 'commission.requested')?_c('MediaComponent',{staticClass:"my-2",scopedSlots:_vm._u([{key:"right",fn:function(){return [(_vm.account)?_c('BaseImage',{staticClass:"is-96x96 is-hidden-mobile",attrs:{"src":_vm.account.icon_url,"is-rounded":""}},[_c('img',{attrs:{"src":require("@/assets/img/undraw/profile_pic.svg")}})]):_vm._e()]},proxy:true}],null,true)},[_c('BaseNotification',[_c('BaseTextPlain',{attrs:{"text":_vm.commission.summary}})],1)],1):_vm._e()],1)],1)])}),_c('li',{staticClass:"steps-segment is-active is-dashed"},[_c('p',{staticClass:"steps-marker is-primary"},[_c('span',{staticClass:"icon"},[_c('SvgInfoCircle',{attrs:{"size":"20","stroke-width":"2.5"}})],1)]),_c('div',{staticClass:"steps-content"},[_c('MediaComponent',[_c('i18n',{attrs:{"path":"status","tag":"p"},scopedSlots:_vm._u([{key:"status",fn:function(){return [_c('StatusTag',{staticClass:"is-medium font-bold uppercase tracking-wide",attrs:{"status":_vm.commission.status}})]},proxy:true}])})],1)],1)]),([
        'requested',
        'active',
        'pending',
        'complete',
        'idle',
        'finished'
      ].includes(_vm.commission.status))?_c('li',{staticClass:"steps-segment"},[_c('p',{staticClass:"steps-marker has-text-primary"},[_c('span',{staticClass:"icon"},[_c('SvgArrowRight',{attrs:{"size":"20","stroke-width":"2.5"}})],1)]),_c('div',{staticClass:"steps-content"},[_c('MediaComponent',[_c('div',{staticClass:"buttons"},[(['active', 'pending'].includes(_vm.commission.status))?_c('RouterLink',{staticClass:"button has-text-link",attrs:{"to":{
                name: 'commission.upload',
                params: { COMMISSION_ID: _vm.COMMISSION_ID },
              }},domProps:{"textContent":_vm._s(_vm.$t('controls.upload-file'))}}):_vm._e(),(_vm.commission.status === 'requested')?_c('CancelButton',{attrs:{"commission-id":_vm.COMMISSION_ID}}):_vm._e()],1)]),([
            'active', 'pending', 'complete', 'idle', 'finished'
          ].includes(_vm.commission.status))?_c('MediaComponent',[_c('MessageForm',{attrs:{"commission-id":_vm.commission.id}})],1):_vm._e()],1)]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }