

































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { MetaInfo } from 'vue-meta';
import { ApiResource } from '@/types';
import StatusTag from '@/components/media/CommissionStatusTag.vue';

export default Vue.extend({
  components: {
    CancelButton: () => import(/* webpackChunkName: "c-r-cb" */ '@/components/commission/CancelButton.vue'),
    FileDeleteButton: () => import(/* webpackChunkName: "c-f-rm" */ '@/components/commission/FileDeleteButton.vue'),
    FileDownloadButton: () => import(/* webpackChunkName: "c-f-dl" */ '@/components/commission/FileDownloadButton.vue'),
    ItemPayButton: () => import(/* webpackChunkName: "c-i-pay" */ '@/components/commission/ItemPayButton.vue'),
    MediaCommissionFile: () => import(/* webpackChunkName: "md-c-f" */ '@/components/media/CommissionFile.vue'),
    MediaCommissionItem: () => import(/* webpackChunkName: "md-c-i" */ '@/components/media/CommissionItem.vue'),
    MessageForm: () => import(/* webpackChunkName: "f-c-msg" */ '@/components/commission/MessageForm.vue'),
    StatusTag,
  },
  data() {
    return {
      error: null as Error|null,
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Get',
      currentUserId: 'account/Id',
      find: 'commission/Find',
      findFile: 'commission/file/Find',
      findItem: 'commission/item/Find',
      findMessage: 'commission/message/Find',
      findProfile: 'profiles/Find',
      list: 'commission/event/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    commission(): ApiResource.Commission {
      return this.find(this.COMMISSION_ID);
    },
    events(): ApiResource.Event[] {
      return this.list(this.COMMISSION_ID)
        .filter((e: ApiResource.Event) => ![
          'commission.file.created',
          'commission.file.downloaded',
        ].includes(e.type))
        .sort((a: ApiResource.Event, b: ApiResource.Event) => {
          if (a.created_at > b.created_at) return 1;
          if (a.created_at < b.created_at) return -1;
          return 0;
        });
    },
    profile(): ApiResource.Profile | undefined {
      if (!this.commission) return undefined;
      return this.findProfile(this.commission.business_id);
    },
  },
  methods: {
    handleError(e: Error) {
      this.error = e;
    },
    stringify: (obj: any): string => JSON.stringify(obj),
    getSvgComponentNameForEventType(type: string): string {
      let component;

      switch (type) {
        case 'commission.waitlisted':
          component = 'Inbox';
          break;

        case 'commission.requested':
          component = 'Star';
          break;

        case 'commission.request.accepted':
          component = 'Check';
          break;

        case 'commission.request.rejected':
        case 'commission.status.disapproved_completion':
          component = 'XCircle';
          break;

        case 'commission.item.created':
          component = 'PlusCircle';
          break;

        case 'commission.item.refunded':
          component = 'Lock';
          break;

        case 'commission.item.paid':
          component = 'Unlock';
          break;

        case 'commission.file.created':
          component = 'FilePlus';
          break;

        case 'commission.file.uploaded':
          component = 'UploadCloud';
          break;

        case 'commission.file.deleted':
          component = 'FileMinus';
          break;

        case 'commission.message.created':
          component = 'MessageCircle';
          break;

        case 'commission.status.complete':
          component = 'CheckCircle';
          break;

        case 'commission.status.active':
        case 'commission.status.pending_customer_action':
        default:
          component = 'InfoCircle';
          break;
      }

      return `Svg${component}`;
    },
    isEventAutomatic(event: ApiResource.Event) {
      return event.additional_data?.automatic === true;
    },
  },
  meta(): MetaInfo {
    return {
      title: this.$t('title').toString(),
    };
  },
});
